export const masterClass = {
  title: 'Master Class',
  add: 'CREATE A MASTER CLASS',
  listTitle: 'Title',
  description: 'Description',
  instructorName: 'Instructor Name',
  actions: 'Actions',
  delete_success_message: 'Master class was successfully deleted',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to remove this master class ?',
  masterClass_no_data: 'No master class found',
  add_masterclass_title: 'Create a master class',
  update_masterclass_title: 'Edit a master class',
  view_masterclass_title: 'View master class details',
  create_success_message: 'Master class has been created successfully',
  update_success_message: 'Master class has been updated successfully',
  create_tag_success_message: 'Tag was successfully created',
  no_related_class: 'No related master class found',
  form_fields: {
    title: {
      label: 'Title*',
      placeholder: 'Title',
      max_length_error_message: 'Title cannot be longer than 50 characters',
      required_error_message: 'Title field is required',
    },
    instructorName: {
      label: 'Instructor Name*',
      placeholder: 'Instructor Name',
      required_error_message: 'Instructor name field is required',
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Description field is required',
    },
    video: {
      label: 'Video Url*',
      placeholder: 'Video url',
      required_error_message: 'Video Url field is required',
    },
    tags: {
      label: 'Tags*',
      placeholder: 'Tags',
      required_error_message: 'Tags field is required',
      max_length_error_message: 'At least one tag is required',
    },
    learningPoints: {
      label: 'What you will learn ?*',
      placeholder: 'What you will learn ?',
      required_error_message: 'What you will learn field is required',
      max_length_error_message: 'At least one learning point is required',
    },
    relatedMasterClass: {
      label: 'Related master class*',
      placeholder: 'Related master class',
      required_error_message: 'Related master class field is required',
    },
  },
};
