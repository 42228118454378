export const common = {
  published: 'Publish',
  discard: 'Cancel',
  save_and_publish: 'Save & Publish',
  search: 'Search',
  actions: 'Actions',
  delete: 'Delete',
  cancel: 'Cancel',
  confirm: 'Confirm',
  user_reports: 'User Reports',
  revenue_reports: 'Revenue Reports',
  ads_users_report: 'Active Ads Reports',
  Logout: 'Logout',
  confirm_logout_title: 'Confirm Logout',
  confirm_logout_message: 'Are you sure you want to logout ?',
  filter: 'Select Category',
  clear: 'Clear',
  no_image_found: 'No image found',
  arts_of_week: 'Arts of the week',
  artists_of_week: 'Artist of the week',
  no_data: 'No data found',
  only_5_boxes: 'You can select maxiumn 5 arts of the week only.',
  created_five_artists: 'Artists of the week added successfully',
  created_five_arts: 'Arts of the week added successfully',
  rowsperpage: 'Rows per page',
  export: 'Export',
  confirm_delete_artist_message: 'Are you sure you want to remove this Artist?',
  confirm_delete_art_message: 'Are you sure you want to remove this Art?',
  something_wrong_happend: 'Something went wrong',
  title: 'Title',
  price: 'Price',
  image: 'Image',
  loading: 'loading',
};
