/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import CreatableSelect from 'react-select/creatable';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Grid, Stack, Button, CircularProgress, Container, Backdrop } from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../../components/button/CustomLoadingButton';
import FormProvider from '../../../components/hook-form/FormProvider';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import MasterClass from '../../../models/MasterClass';
import { masterClassApi } from '../../../store/masterClass/api/MasterClass.api';
import { MasterClassFormProps } from './MasterClass-form.types';
import { CreateMasterClassSchema } from './create-masterclass.schema';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import AdminVideoControl from '../../adds/menubar/pending/form/VideoControl';
import { clearNewTag, createTag, getTags } from '../../../store/tags/actions';
import { tagsActionTypes } from '../../../store/tags/models';
import { MasterClassActionTypes } from '../../../store/masterClass/slice/MasterClass-slice.types';
import { colors } from '../../../theme/sharedTheme';

const { updateMasterClass, createMasterClass } = masterClassApi.endpoints;

interface TagOption {
  value: string;
  label: string;
}

// Example tag options

export default function MasterClassForm({ currentMasterClass, isUpdate, onClose, isEyeIconClicked }: MasterClassFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, MasterClassActionTypes>>();
  const dispatchTags = useDispatch<ThunkDispatch<RootState, undefined, tagsActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);

  const tagList = useSelector((state: RootState) => state.tags.tags);
  const newTags = useSelector((state: RootState) => state.tags.newTag);
  const [tagOptionsData, setTagOptions] = useState<TagOption[]>([]);
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const defaultValues = useMemo<MasterClass>(
    () => ({
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '' }],
    }),
    [currentMasterClass],
  );

  const methods = useForm<MasterClass>({
    resolver: yupResolver(CreateMasterClassSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'learningPoints',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ point: '' }); // Add an initial field if none exist
    }
  }, [fields, append]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchTags(getTags());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentMasterClass]);

  useEffect(() => {
    if (isUpdate && currentMasterClass) {
      const posturl = currentMasterClass?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
    }
  }, [currentMasterClass, isUpdate, setValue]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '' }],
    };
    methods.reset(updatedDefaultValues);
  }, [currentMasterClass, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };
  console.log('tagIds', values.tagIds, newTags);
  const create = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid) {
        const formData = new FormData();
        formData.append('title', values.title || '');
        formData.append('instructorName', values.instructorName || '');
        formData.append('description', values.description || '');
        formData.append('video', values.video || '');
        const learningPoints = values.learningPoints?.map((item) => item.point) ?? [];
        formData.append('learningPoints', String(learningPoints));
        formData.append('tagIds', String(values.tagIds));

        await dispatch(createMasterClass(formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('masterClass.create_success_message')));
        dispatchTags(clearNewTag());
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentMasterClass?.id && !postError) {
        const data = {
          title: values.title,
          description: values.description,
          tagIds: values.tagIds,
          learningPoints: values.learningPoints,
        };
        await dispatch(updateMasterClass(currentMasterClass?.id, data));
        reset(defaultValues);
        dispatch(alertSuccess(t('masterClass.update_success_message')));
        dispatchTags(clearNewTag());
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);
    setVideoPopupOpen(true);
  };

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('video')) {
        setPostError(undefined);
        setPostURL(imageUrl || undefined);
        setPostType(fileType);
        setValue('video', logoFile); // Update form state with video file
        clearErrors('video'); // Clear form error for video
      } else {
        setPostError('Please choose an video file.');
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const convertToTagOptions = (tagIds: string[] | undefined): TagOption[] => {
    if (!tagIds) return [];
    return tagIds?.map((id) => {
      const tagVal = tagList?.find((tag) => String(tag.id) === id);
      return { value: id, label: tagVal ? tagVal.name : '' };
    });
  };

  useEffect(() => {
    const initialTagOptions: TagOption[] = (tagList || [])?.map((tag) => ({
      value: String(tag.id),
      label: tag.name,
    }));
    setTagOptions(initialTagOptions);
  }, [tagList]);

  useEffect(() => {
    if (newTags?.masterClassTag) {
      const newTagId = newTags.masterClassTag.id;
      const newTagLabel = newTags.masterClassTag.name;

      // Ensure newTagId is defined
      if (newTagId !== undefined) {
        // Create new tag option
        const newTagOption: TagOption = { value: newTagId.toString(), label: newTagLabel };

        // Update tag list with the new tag
        const updatedTagList = [...(tagList || []), { id: newTagId, name: newTagLabel }];

        // Exclude the newly created tag option from the options list
        const updatedTagOptions: TagOption[] = updatedTagList
          ?.filter((tag): tag is { id: string; name: string } => 'id' in tag && 'name' in tag) // Type guard for valid tags
          ?.filter((tag) => tag.id !== newTagId) // Exclude the newly created tag
          ?.map((tag) => ({
            value: tag.id.toString(), // Ensure value is a string
            label: tag.name,
          }));

        // Update form values with the newly created tag
        const currentTagIds: string[] = watch('tagIds') || [];
        if (!currentTagIds.includes(newTagId.toString())) {
          setValue('tagIds', [...currentTagIds, newTagId.toString()], { shouldValidate: true });
        }

        // Update Data with the updatedTagOptions
        setTagOptions([...updatedTagOptions, newTagOption]);

        // Optionally update your tagList in Redux or local state if needed
        // dispatch(updateTagList(updatedTagList));
      }
    }
  }, [newTags, setValue, watch, tagList]);

  const createNewTag = useCallback(
    async (newTagName: string) => {
      try {
        await dispatch(createTag(newTagName));
        dispatch(alertSuccess(t('masterClass.create_tag_success_message')));
      } catch (err) {
        dispatch(alertError(t('masterClass.create_tag_error_message')));
        console.error(err);
      }
    },
    [dispatch, t, values.tagIds, setValue, alertSuccess, alertError],
  );

  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('masterClass.form_fields.title.placeholder')}
                    label={t('masterClass.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    placeholder={t('masterClass.form_fields.description.placeholder')}
                    name={'description'}
                    label={t('masterClass.form_fields.description.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'instructorName'}
                    placeholder={t('masterClass.form_fields.instructorName.placeholder')}
                    label={t('masterClass.form_fields.instructorName.placeholder')}
                    disabled={isEyeIconClicked || !!isUpdate}
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Grid>
                  <div>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.video.label')}
                    </label>
                    <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                      {postURL ? (
                        <div
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <video
                            style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                            src={postURL}
                            onClick={openVideoPopup}
                          ></video>
                          <span
                            style={{
                              backgroundColor: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '9999px',
                              position: 'absolute',
                              width: '1.5rem',
                              height: '1.5rem',
                              cursor: 'pointer',
                              justifyContent: 'center',
                            }}
                          >
                            <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                          </span>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                          <label
                            htmlFor="logos"
                            style={{
                              width: '44px',
                              height: '44px',
                              minWidth: '44px',
                              backgroundColor: '#f0f0f0',
                              border: '1px dashed #ccc',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <AddPhotoIcon />
                          </label>
                        </div>
                      )}
                      {!isUpdate && (
                        <Button
                          className="artgapi-upload-btn"
                          style={{
                            textTransform: 'capitalize',
                            boxShadow: 'inset 0px -1px 0px #E7E7E7',
                            marginLeft: '15px',
                            backgroundColor: '#ffcc33',
                          }}
                          size="small"
                          variant="contained"
                          component="label"
                          disabled={isEyeIconClicked}
                        >
                          {t('articles.upload')}
                          <input hidden type="file" id="video-url" onChange={handleLogoUpload} accept="video/*" />
                        </Button>
                      )}
                    </div>
                    {errors?.video && !postError && (
                      <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                        {t('masterClass.form_fields.video.required_error_message')}
                      </p>
                    )}
                    {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>{postError}</p>}
                  </div>
                </Grid>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.learningPoints.label')}
                    </label>
                    {fields?.map((field, index) => (
                      <Box
                        key={field.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                          gap: 1,
                        }}
                      >
                        <RHFTextField
                          name={`learningPoints[${index}].point`}
                          placeholder={t('masterClass.form_fields.learningPoints.placeholder')}
                          disabled={isEyeIconClicked}
                          sx={{ flex: 1 }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {index > 0 && (
                            <span onClick={() => remove(index)} style={{ cursor: 'pointer' }}>
                              <RemoveCircleIcon />
                            </span>
                          )}
                          {!isEyeIconClicked && index === fields.length - 1 && (
                            <span onClick={() => append({ point: '' })} style={{ cursor: 'pointer' }}>
                              <AddCircleIcon />
                            </span>
                          )}
                        </div>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.tags.label')}
                    </label>

                    <Controller
                      control={control}
                      name="tagIds"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CreatableSelect
                          isMulti
                          onChange={(selectedOptions: any) => {
                            const selectedTagIds = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                            setValue('tagIds', selectedTagIds, { shouldValidate: true }); // Update form state and re-trigger validation
                          }}
                          onCreateOption={createNewTag}
                          value={convertToTagOptions(value)}
                          options={tagOptionsData}
                          placeholder={t('masterClass.form_fields.tags.placeholder')}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: errors.tagIds ? '#d32f2f' : provided.borderColor, // Set border color based on error
                            }),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {errors?.tagIds && (
                  <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                    {t('masterClass.form_fields.tags.required_error_message')}
                  </p>
                )}
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>

      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
    </>
  );
}
