/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MasterClassFormProps } from './MasterClass-form.types';
import { theme } from '../../../theme/theme';
import MasterClass from '../../../models/MasterClass';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';

export default function ViewMasterClass({ currentMasterClass, isUpdate, isEyeIconClicked }: MasterClassFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  const { t } = useTranslation();
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const defaultValues = useMemo<MasterClass>(
    () => ({
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '' }],
      relatedMasterClasses: currentMasterClass?.relatedMasterClasses || [],
    }),
    [currentMasterClass],
  );

  const methods = useForm<MasterClass>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentMasterClass]);

  useEffect(() => {
    if (currentMasterClass) {
      const posturl = currentMasterClass?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
    }
  }, [currentMasterClass]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '' }],
      relatedMasterClasses: currentMasterClass?.relatedMasterClasses || [],
    };
    methods.reset(updatedDefaultValues);
  }, [currentMasterClass, isUpdate]);

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('masterClass.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.description}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.instructorName.placeholder')}</Typography>
                  <label style={customStyle}>{values?.instructorName}</label>
                </div>
              </Box>
            </Box>

            {/* Advertisement plan type Information Section */}
            <Box>
              <Typography sx={{ marginBottom: '10px' }}>{t('masterClass.form_fields.video.label')}</Typography>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                {values?.video && (
                  <Grid>
                    <div>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {postURL && (
                          <div
                            style={{
                              width: '100px',
                              height: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                            }}
                          >
                            <video
                              style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                              src={postURL}
                              onClick={openVideoPopup}
                            ></video>
                            <span
                              style={{
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '9999px',
                                position: 'absolute',
                                width: '1.5rem',
                                height: '1.5rem',
                                cursor: 'pointer',
                                justifyContent: 'center',
                              }}
                            >
                              <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
              </Box>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: '10px', marginTop: '20px' }}>{t('masterClass.form_fields.learningPoints.placeholder')}</Typography>

              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ paddingTop: '0px' }}>
                    <ul>
                      {values.learningPoints?.map((learningPoint, index) => (
                        <li key={index}>
                          <label>{learningPoint.point}</label>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 3, mb: 0 }}>
              <Typography variant="subtitle1">{t('masterClass.form_fields.tags.placeholder')}</Typography>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {values.tagIds?.map((item: any, index) => (
                  <div key={index} style={{ marginTop: '0', marginRight: '10px' }}>
                    <Button variant="outlined" sx={{ cursor: 'not-allowed !important', marginBottom: '7px' }}>
                      {item.name}
                    </Button>
                  </div>
                ))}
              </FormGroup>
            </Box>
            {/* <Box sx={{ mt: 3, mb: 0 }}>
              <Typography variant="subtitle1">{t('masterClass.form_fields.relatedMasterClass.placeholder')}</Typography>
              {values.relatedMasterClasses && values.relatedMasterClasses.length > 0 ? (
                <h1>fsfdsf</h1>
              ) : (
                <label>{t('masterClass.no_related_class')}</label>
              )}
            </Box> */}
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
    </>
  );
}
